import * as React from "react";
import { useEffect } from "react";
import { PageProps } from "gatsby";
import { navigate } from "gatsby-link";

const { GATSBY_SANTA_POST_DELIVERY_URL } = process.env;

const PostDelivery = ({ location, params }: PageProps) => {
  useEffect(() => {
    if (GATSBY_SANTA_POST_DELIVERY_URL) {
      void navigate(GATSBY_SANTA_POST_DELIVERY_URL);
    } else {
      void navigate("/");
    }
  }, []);
  return null;
};

export default PostDelivery;
